import React from 'react';
 // Import CSS for consistent styling

const AddMetamask = () => {
    const addChain = async () => {
        try {
            await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [{
                    chainId: '0x7C7', 
                    chainName: 'Mazze Devnet',
                    rpcUrls: ['https://devnet-rpc.mazze.io'], 
                    nativeCurrency: {
                        name: 'MAZZE',
                        symbol: 'MAZZE', 
                        decimals: 18,
                    },
                    blockExplorerUrls: ['https://mazzescan.io'], 
                }],
            });
        } catch (error) {
            console.error('Error to add', error);
        }
    };

    return (
        <div className="infoBox"> {/* Use the same styling */}
            <h3>Add Mazze to MetaMask</h3>
            <button onClick={addChain} className="addMetamaskButton">Add Mazze to MetaMask</button>
        </div>
    );
};

export default AddMetamask;