import React, { createContext, useContext, useState, useCallback } from 'react';
import SDK from '@mazze-labs/mazze-js-sdk';
import { CHAIN_ID, RPC_URL } from '../config';

const AccountContext = createContext();

export const AccountProvider = ({ children }) => {
  const [walletAddress, setWalletAddress] = useState(() => localStorage.getItem('walletAddress') || '');
  const [privateKey, setPrivateKey] = useState(() => localStorage.getItem('privateKey') || '');
  const [isLoggedIn, setIsLoggedIn] = useState(() => localStorage.getItem('isLoggedIn') === 'true');

  const connect = useCallback(async (key) => {
    try {
      const proxyProvider = new SDK.ProxyProvider({
        url: RPC_URL, 
        networkId: CHAIN_ID, 
      });

      const account = proxyProvider.wallet.addPrivateKey(key); 
      const walletAddress = account.address;

      if (!walletAddress) {
        console.error('Wallet address not found');
        return;
      }

      // Save address and privateKey to local storage
      localStorage.setItem('walletAddress', walletAddress);
      localStorage.setItem('privateKey', key);
      localStorage.setItem('isLoggedIn', 'true');

      // Update state
      setWalletAddress(walletAddress);
      setPrivateKey(key);
      setIsLoggedIn(true);
    } catch (error) {
      console.error('Error importing wallet with private key:', error);
    }
  }, []);

  const disconnect = () => {
    localStorage.removeItem('walletAddress');
    localStorage.removeItem('privateKey');
    localStorage.removeItem('isLoggedIn');
    setWalletAddress('');
    setPrivateKey('');
    setIsLoggedIn(false);
  };

  return (
    <AccountContext.Provider value={{ walletAddress, privateKey, isLoggedIn, connect, disconnect }}>
      {children}
    </AccountContext.Provider>
  );
};

export const useAccount = () => {
  return useContext(AccountContext);
};