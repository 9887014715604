import React from 'react';

function Footer() {
    return (
        <footer className="footer">
            <p className="footerText">© 2024 Mazze Web Wallet. All Rights Reserved.</p>
            <p className="footerText">
                <a href="/privacy" className="footerLink">Privacy Policy</a> | 
                <a href="/terms" className="footerLink">Terms of Service</a>
            </p>
        </footer>
    );
}

export default Footer;
