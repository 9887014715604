import React, { useState } from 'react';
import SDK from '@mazze-labs/mazze-js-sdk'; // Ensure this is browser-compatible
import BigNumber from 'bignumber.js'; // Import BigNumber from bignumber.js
import { RPC_URL, CHAIN_ID } from '../config'; // Contains rpcUrl and chainId
import { useAccount } from '../context/AccountContext';

const TransferMazzeToEvm = () => {
  const { privateKey, walletAddress } = useAccount(); // Ensure privateKey and walletAddress are obtained securely
  const [evmAddress, setEvmAddress] = useState('');
  const [amount, setAmount] = useState(''); // Amount in MAZZE
  const [loading, setLoading] = useState(false);
  const [transactionHash, setTransactionHash] = useState(null);
  const [error, setError] = useState(null);

  const transferMazzeToEvm = async () => {
    try {
      console.log('Starting transfer process...');
      setLoading(true);
      setError(null);
      setTransactionHash(null);

      if (!privateKey || !walletAddress) {
        console.error('Wallet address or private key is undefined.');
        setError('Wallet address or private key is undefined.');
        setLoading(false);
        return;
      }

      if (!evmAddress) {
        console.error('EVM address is required.');
        setError('EVM address is required.');
        setLoading(false);
        return;
      }

      if (isNaN(amount) || Number(amount) <= 0) {
        console.error('Invalid amount.');
        setError('Invalid amount.');
        setLoading(false);
        return;
      }

      // Convert amount to Mazzy (smallest unit) using BigNumber
      const decimals = 18;
      const valueInMazzy = new BigNumber(amount).multipliedBy(new BigNumber(10).pow(decimals));
      console.log(`Converted amount to Mazzy: ${valueInMazzy.toString()}`);

      // Use .toFixed(0) to avoid scientific notation
      const valueAsString = valueInMazzy.toFixed(0);

      // Create ProxyProvider instance
      const mazze = await SDK.ProxyProvider.create({
        url: RPC_URL,
        networkId: CHAIN_ID,
      });
      console.log('ProxyProvider instance created');

      // Add the account with the private key
      const account = mazze.wallet.addPrivateKey(privateKey);
      console.log(`Account added with address: ${account.address}`);

      // Get the internal contract
      const crossSpaceCall = mazze.InternalContract('CrossSpaceCall');
      console.log('Internal contract "CrossSpaceCall" retrieved');

      // Execute the transfer
      const transfer = crossSpaceCall.transferEVM(evmAddress).sendTransaction({
        from: account,
        value: valueAsString, // Ensure value is a string representing an integer
      });
      console.log('Transfer transaction created:', transfer);

      // Wait for the transaction to be executed
      const receipt = await transfer.executed();
      console.log('Transaction executed, receipt:', receipt);

      setTransactionHash(receipt.transactionHash);
      setLoading(false);
    } catch (error) {
      console.error('Error transferring MAZZE:', error);
      setError(`An error occurred: ${error.message}`);
      setLoading(false);
    }
  };

  return (
    <div className="infoBox">
      <h3>Transfer MAZZE to EVM</h3>
      <input
        type="text"
        placeholder="EVM Address"
        value={evmAddress}
        onChange={(e) => setEvmAddress(e.target.value)}
        className="sendInput"
      />
      <input
        type="text"
        placeholder="Amount in MAZZE"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        className="sendInput"
      />
      <button onClick={transferMazzeToEvm} className="sendButton" disabled={loading}>
        {loading ? 'Transferring...' : 'Transfer'}
      </button>
      {error && <p className="errorMessage">{error}</p>}
      {transactionHash && (
        <div className="receiptInfo">
          <p>Transfer succeeded!</p>
          <p>Transaction Hash:</p>
          <pre>{transactionHash}</pre>
        </div>
      )}
    </div>
  );
};

export default TransferMazzeToEvm;
