// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AccountProvider } from './context/AccountContext';
import CreateWallet from './pages/CreateWallet';
import ImportWallet from './pages/ImportWallet';
import Dashboard from './pages/Dashboard';


function App() {
  return (
    <Router>
      <AccountProvider>
      <Routes>
        <Route path="/" element={<CreateWallet />} />
        <Route path="/import" element={<ImportWallet />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
      </AccountProvider>
    </Router>
  );
}

export default App;
