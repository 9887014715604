import React, { useState, useEffect } from 'react';
import { CHAIN_ID, RPC_URL } from '../config';
const SDK = require("@mazze-labs/mazze-js-sdk");
const { useAccount } = require("../context/AccountContext");

const privateKey = process.env.REACT_APP_PRIVATE_KEY;

const Faucet = () => {
    const { walletAddress } = useAccount();
    const [showPopup, setShowPopup] = useState(false);
    const [txHash, setTxHash] = useState('');
    const [claimedAmount] = useState('1');
    const [showMessage, setShowMessage] = useState(false);
    const [timer, setTimer] = useState(15);

    useEffect(() => {
        let countdown;
        if (showPopup) {
            countdown = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer > 1) {
                        return prevTimer - 1;
                    } else {
                        clearInterval(countdown);
                        setShowMessage(true);
                        return 0;
                    }
                });
            }, 1000);
        }
        return () => clearInterval(countdown);
    }, [showPopup]);

    const run = async () => {
        try {
            const mazze = await SDK.ProxyProvider.create({ url: RPC_URL, networkId: CHAIN_ID });
            mazze.wallet.addPrivateKey(privateKey);

            const tx = new SDK.Transaction({
                from: 'mazze:aat32zfpx9yvypn42tjtyby86evbkb470p06zuzbns',
                to: walletAddress,
                value: '10000000000000000000', // 10 MAZZE (presupunând 18 zecimale)
            });

            const txHash = await mazze.sendTransaction(tx);
            console.log('Transaction Hash:', txHash);
            setTxHash(txHash.replace(/^0x/, ''));
            setShowPopup(true);
        } catch (error) {
            console.error('Error sending transaction:', error);
        }
    };

    const handleClose = () => {
        setShowPopup(false);
        window.location.reload();
    };

    return (
        <div className="infoBox">
            <h3>Faucet</h3>
            <button onClick={run} className="faucet-claimButton">Claim</button>

            {showPopup && (
                <div className="faucet-popup">
                    <div className="faucet-popupContent">
                        {!showMessage ? (
                            <div>
                                <h3>Please wait {timer} seconds...</h3>
                            </div>
                        ) : (
                            <div>
                                <h3>Transaction Successful!</h3>
                                <p>Amount Claimed: {claimedAmount} MAZZE</p>
                                <p>Transaction Hash:</p>
                                <p className="faucet-txHash">
                                    <a href={`https://devnet.mazzescan.io/transactions/${txHash}`} target="_blank" rel="noopener noreferrer">
                                        {txHash}
                                    </a>
                                </p>
                                <button onClick={handleClose} className="faucet-closeButton">Close</button>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Faucet;
