import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SDK from '@mazze-labs/mazze-js-sdk';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CryptoJS from 'crypto-js';
import { useAccount } from '../context/AccountContext';
import { CHAIN_ID, RPC_URL } from '../config';

function ImportWallet() {
  const { connect, isLoggedIn } = useAccount();
  const [privateKey, setPrivateKey] = useState('');
  const [jsonPassword, setJsonPassword] = useState('');
  const [showJsonPopup, setShowJsonPopup] = useState(false);
  const [showPemPopup, setShowPemPopup] = useState(false);


  const navigate = useNavigate();

  
  
  useEffect(() => {
    if (privateKey) {
      connect(privateKey);
    }
  }, [privateKey]);

  useEffect(() => {
    if (isLoggedIn) {
    
      navigate('/dashboard');
    }
  }, [isLoggedIn])



 
  // Handle login with JSON file and password
  const handleJsonLogin = async (e) => {
  

    const reader = new FileReader();
    reader.onload = (event) => {
      const content = event.target.result;
      const parsedData = JSON.parse(content);
      const decryptedData = CryptoJS.AES.decrypt(parsedData.encryptedWallet, jsonPassword).toString(CryptoJS.enc.Utf8);
      const walletData = JSON.parse(decryptedData);

     
      setPrivateKey(walletData.privateKey);
      
      // Save address and balance to local storage
   

      setShowJsonPopup(false);
    };
    reader.readAsText(e.target.files[0]);
  };

  // Handle login with PEM file (no password required)
  const handlePemLogin = async (e) => {
  

    const proxyProvider = new SDK.ProxyProvider({
      url: RPC_URL, 
      networkId: CHAIN_ID, 
    });

    const reader = new FileReader();
    reader.onload = async (event) => {
      const pemContent = event.target.result;
      
      const pemPrivateKey = pemContent.replace(/-----BEGIN PRIVATE KEY-----|-----END PRIVATE KEY-----/g, '').trim();

      // Log in with the private key after setting it
      await connect(pemPrivateKey); 

      setShowPemPopup(false); // Close the PEM popup
    };
    reader.readAsText(e.target.files[0]);

  };

  

  return (
    <div className="container">
      <Navbar />
      <div className="mainContent">

          <div className="card">
            <h2 className="heading">Import Wallet</h2>

            <div className="inputGroup">
              <input
                type="text"
                placeholder="Enter your private key"
                value={privateKey}
                onChange={(e) => setPrivateKey(e.target.value)}
                className="input"
              />
              <button className="button">Login with Private Key</button>
            </div>

            <button onClick={() => setShowJsonPopup(true)} className="button">Login with JSON</button>
            <button onClick={() => setShowPemPopup(true)} className="button">Login with PEM</button>

            {/* JSON Popup */}
            {showJsonPopup && (
              <div className="popup">
                <h3>Enter Password for JSON</h3>
                <input
                  type="password"
                  placeholder="Password"
                  value={jsonPassword}
                  onChange={(e) => setJsonPassword(e.target.value)}
                  className="input"
                />
                <input
                  type="file"
                  accept=".json"
                  onChange={(e) => handleJsonLogin(e)}
                  className="input"
                />
                <button onClick={handleJsonLogin} className="button">Login</button>
                <button onClick={() => setShowJsonPopup(false)} className="button">Cancel</button>
              </div>
            )}

            {/* PEM Popup */}
            {showPemPopup && (
              <div className="popup">
                <h3>Upload PEM File</h3>
                <input
                  type="file"
                  accept=".pem"
                  onChange={(e) => handlePemLogin(e)}
                  className="input"
                />
                <button onClick={handlePemLogin} className="button">Login</button>
                <button onClick={() => setShowPemPopup(false)} className="button">Cancel</button>
              </div>
            )}
          </div>


      </div>
      <Footer />
    </div>
  );
}

export default ImportWallet;
