import React from 'react';  // Import React
import Navbar from '../components/Navbar';  // Importă componenta Navbar
import Footer from '../components/Footer';  // Importă componenta Footer
import { CHAIN_ID, RPC_URL } from '../config';
const SDK = require("@mazze-labs/mazze-js-sdk");  // Import the SDK module
const CryptoJS = require("crypto-js");  // Import CryptoJS for encryption


function CreateWallet() {
  const [walletCreated, setWalletCreated] = React.useState(false);
  const [address, setAddress] = React.useState('');
  const [privateKey, setPrivateKey] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [showPrivateKey, setShowPrivateKey] = React.useState(false);  // New state for showing/hiding private key

  const handleCreateWallet = async () => {
    try {
      const mazze = await SDK.ProxyProvider.create({ url: RPC_URL, networkId: CHAIN_ID });
      const wallet = await mazze.wallet.addRandom();

      setAddress(wallet.address);
      setPrivateKey(wallet.privateKey);
      setWalletCreated(true);

      console.log(`Address: ${wallet.address}`);
      console.log(`Private Key: ${wallet.privateKey}`);
    } catch (error) {
      console.error('Error creating the wallet:', error);
    }
  };

  const togglePrivateKeyVisibility = () => {
    setShowPrivateKey(!showPrivateKey);  // Toggle the state
  };

  const downloadPEM = () => {
    const pemContent = `-----BEGIN PRIVATE KEY-----
${privateKey}
-----END PRIVATE KEY-----`;
    
    const blob = new Blob([pemContent], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'wallet_private_key.pem';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const downloadEncryptedJSON = () => {
    if (!password) {
      alert('Please enter a password!');
      return;
    }

    const walletData = {
      address: address,
      privateKey: privateKey,
    };

    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(walletData), password).toString();
    
    const jsonContent = JSON.stringify({ encryptedWallet: encryptedData });
    
    const blob = new Blob([jsonContent], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'encrypted_wallet.json';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <div className="container">
      <Navbar />
      <div className="mainContent">
        {!walletCreated ? (
          <div className="card">
            <h2 className="heading">Create New Wallet</h2>
            <button className="button" onClick={handleCreateWallet}>Create Wallet</button>
          </div>
        ) : (
          <div className="card">
            <h2 className="heading">Wallet Created Successfully!</h2>
            
            <div className="addressBox">
              Your Address: <br /> {address}
            </div>
            
            <div className="text">
              Your Private Key: {showPrivateKey ? privateKey : ''}
            </div>
            <button className="button" onClick={togglePrivateKeyVisibility}>
              {showPrivateKey ? 'Hide' : 'Show'} Private Key
            </button>
            
            <p className="warning">
              IMPORTANT: Please save your private key securely. Do not share it with anyone!
            </p>
            
            <input
              type="password"
              className="input"
              placeholder="Enter a password for the JSON file"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button className="button" onClick={downloadEncryptedJSON}>Download Encrypted JSON</button>
            <button className="button" onClick={downloadPEM}>Download Private Key (PEM)</button>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default CreateWallet;
