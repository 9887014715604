/* global BigInt */
import React, { useState } from 'react';
import { useAccount } from "../context/AccountContext";
import { ProxyProvider, Transaction } from '@mazze-labs/mazze-js-sdk';
import { CHAIN_ID, RPC_URL } from '../config';

const Send = () => {
  const { privateKey, walletAddress } = useAccount();
  const [toAddress, setToAddress] = useState('');
  const [amount, setAmount] = useState(''); // Amount in MAZZE tokens
  const [error, setError] = useState(null);
  const [txHash, setTxHash] = useState(null);

  const run = async () => {
    try {
      console.log("Starting transaction process");

      if (!walletAddress || !privateKey) {
        setError('Wallet address or private key is undefined.', privateKey);
        return;
      }
      if (!toAddress || isNaN(amount) || Number(amount) <= 0) {
        setError('Invalid recipient address or amount.');
        return;
      }

      setError(null); // Reset error state

      // Convert amount to Mazzy (MAZZE's smallest unit)
      const decimals = 18;
      const valueInMazzy = BigInt(parseFloat(amount) * Math.pow(10, decimals));

      console.log('Amount in Mazzy:', valueInMazzy.toString());

      // Create ProxyProvider instance using the SDK static method
      const mazze = await ProxyProvider.create({
        url: RPC_URL,
        networkId: CHAIN_ID,
      });

      console.log('ProxyProvider created:', mazze);

      // Add the account with the private key
      mazze.wallet.addPrivateKey(privateKey);
      console.log('Transaction parameters fetched:');
      console.log('Private key added to wallet', privateKey);


      // Prepare the transaction
      const tx = new Transaction({
        from: walletAddress,
        to: toAddress,
        value: valueInMazzy.toString(),
      });

      console.log('Transaction prepared:', tx);
      console.log(mazze.sendTransaction)

      // Send the transaction
      const txHash = await mazze.sendTransaction(tx);
      console.log('Transaction Hash:', txHash);

      setTxHash(txHash);
    } catch (error) {
      console.error('Error sending transaction:', error);
      setError(`An error occurred while sending the transaction: ${error.message}`);
    }
  };

  return (
    <div className="sendModule">
      <h3>Send Funds</h3>
      <input
        type="text"
        placeholder="Recipient Address"
        value={toAddress}
        onChange={(e) => setToAddress(e.target.value)}
        className="sendInput"
      />
      <input
        type="text"
        placeholder="Amount in MAZZE"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        className="sendInput"
      />
      <button onClick={run} className="sendButton">Send</button>
      {error && <p className="errorMessage">{error}</p>}
      {txHash && (
        <div className="transactionInfo">
          <p>Transaction sent successfully!</p>
          <p>Transaction Hash: <span className="txHash">{txHash}</span></p>
        </div>
      )}
    </div>
  );
};

export default Send;