import React from 'react';
import { useAccount } from '../context/AccountContext';

function Navbar() {
    const { isLoggedIn, disconnect } = useAccount();

    return (
        <header className="header">
            <h1 className="logo">Mazze Web Wallet</h1>
            <nav className="nav">
                <a href="/" className="navLink">Home</a>
                {isLoggedIn ? (
                    <a href="/importWallet" className="navLink" onClick={disconnect}>Logout</a>
                ) : (
                    <a href="/import" className="navLink">Connect Wallet</a>
                )}
            </nav>
        </header>
    );
}

export default Navbar;