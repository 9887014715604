// Dashboard.js
import React, { useEffect, useState, useCallback } from 'react';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import AddMetamask from '../components/AddMetamask';
import Faucet from '../components/Faucet';
import Send from '../components/Send';
import { CHAIN_ID, RPC_URL } from '../config';
import TransferMazzeToEvm from '../components/TransferMazzeToEvm'

const SDK = require("@mazze-labs/mazze-js-sdk");

function Dashboard() {
  const [balance, setBalance] = useState('Loading...');
  const [showFaucet, setShowFaucet] = useState(true); // Control Faucet visibility
  const walletAddress = localStorage.getItem('walletAddress');

  const fetchBalance = useCallback(async () => {
    const mazze = await SDK.ProxyProvider.create({ url: RPC_URL, networkId: CHAIN_ID });
    const balance = await mazze.getBalance(walletAddress);
    const balanceInMazze = (balance / 10 ** 18).toFixed(2);
    setBalance(balanceInMazze);

    if (balanceInMazze > 5) {
      setShowFaucet(false);
    } else {
      setShowFaucet(true);
    }
  },[walletAddress]);


  useEffect(() => {
    fetchBalance();
  }, [fetchBalance]);
  
  const handleClaimSuccess = () => {
    setShowFaucet(false); // Hide Faucet after a successful claim
  };

  return (
    <div className="container">
      <Navbar />
      <div className="mainContent">
        <div className="leftColumn">
          <div className="infoBox">
            <h3>Wallet Information</h3>
            <p><strong>Your Address:</strong> {walletAddress.slice(0, 10)}...{walletAddress.slice(-8)}</p>
            <p><strong>Account Balance:</strong> {balance}</p>
          </div>
          {showFaucet && <Faucet onClaimSuccess={handleClaimSuccess} />}
          <AddMetamask />
        </div>
        <div className="rightColumn">
          <Send />
          <TransferMazzeToEvm />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Dashboard;
